import { Storage } from '@/infrastructure/storage/Storage';
import { Token } from '../domain/Types';
import { configuration } from '@/config';
import store from '@/store';

export async function restoreSessionIfExists() {
    const token = Storage.get<Token>(configuration.LOCAL_STORAGE_TOKEN_KEY);
    if (token) {
      try {
        await store.dispatch('sessionStore/restoreWith', token);
      } catch {
        await store.dispatch('sessionStore/closeSession');
      }
    }
}
