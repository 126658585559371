const MS_IN_MINUTE = 60000;
const MS_IN_SECONDS = 1000;

export class Time {
    public static ofMilliseconds(milliseconds: number) {
        return new Time(milliseconds);
    }

    public static ofSeconds(seconds: number) {
        return new Time(seconds * MS_IN_SECONDS);
    }

    public static ofMinutes(minutes: number) {
        return new Time(minutes * MS_IN_MINUTE);
    }

    private constructor(private readonly milliseconds: number) {}

    public asMilliseconds() {
        return this.milliseconds;
    }

    public asSeconds() {
        return this.milliseconds / MS_IN_SECONDS;
    }
}
