import * as LocalStorage from 'local-storage';
import { StorageKey } from './types';
import { StorageItem } from './StorageItem';

export class Storage {

    public static put<T>(item: StorageItem) {
        LocalStorage.set<T>(item.getKey(), item.getValue());
    }

    public static get<T>(key: StorageKey) {
        return LocalStorage.get<T>(key);
    }

    public static clear() {
        LocalStorage.clear();
    }
}
