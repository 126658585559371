import { ApiInstance } from '@/api/ApiInstance';
import { configuration } from '@/config';
import { CreateSessionRequest } from '../rest/request/CreateSessionRequest';
import { CreateSessionResponse } from '../rest/response/CreateSessionResponse';
import { Session } from '../domain/Session';
import { Token } from '../domain/Types';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
import { User } from '@/modules/user/domain/User';
import { UserInformationResponse } from '../rest/response/UserInformationResponse';

export class SessionService {

    private static readonly api = ApiInstance.createFor(configuration.KEYCLOAK_OPENID_URL);

    public static async createSession(createSessionRequest: CreateSessionRequest): Promise<Session> {
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        const { data: createSessionResponse } = await this.api.post<CreateSessionResponse>
            ('/token',  createSessionRequest.toJson(), config);

        const userInformationResponse = await SessionService.fetchUserInformation(createSessionResponse.access_token);
        const user = User.from(userInformationResponse);
        return Session.for(user, createSessionResponse.access_token);
    }

    public static async fetchUserInformation(token: Token): Promise<UserInformationResponse> {
        const { data: userInformationResponse } = await this.api.post<UserInformationResponse>
            ('/userinfo',  {}, { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});

        return userInformationResponse;
    }

    public static async isSessionActive(token: Token): Promise<boolean> {
        let isActive = true;

        try {
            await this.api.post('/userinfo', {}, { headers: AuthorizationHeaderBuilder.buildBearerWith(token) });
        } catch {
            isActive = false;
        }

        return isActive;
    }
}
