













import {Component, Vue} from 'vue-property-decorator';
import HeadBarLogo from './HeadBarLogo.vue';
import HeadBarMenu from './HeadBarMenu.vue';

@Component({
    components: {HeadBarLogo, HeadBarMenu},
})
export default class HeadBar extends Vue {

}
