import axios, {AxiosInstance} from 'axios';
import store from '@/store';

const INVALID_TOKEN_ERROR = 'invalid_token';
const UNAUTHORIZED = 401;

export class ApiInstance {
    public static createFor(baseURL: string): AxiosInstance {
        const instance = axios.create({baseURL});
        this.addInvalidTokenInterceptorTo(instance);
        return instance;
    }

    private static addInvalidTokenInterceptorTo(instance: AxiosInstance) {
        instance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status === UNAUTHORIZED) {
                if (error.response.data.error === INVALID_TOKEN_ERROR) {
                    store.dispatch('sessionStore/closeSession');
                }
            } else {
                Promise.reject(error);
                throw new Error(error.response.data.errorMessage);
            }
        });
    }
}
