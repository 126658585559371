import { Token } from '@/modules/session/domain/Types';

interface AuthorizationHeader {
    Authorization: string;
}

export class AuthorizationHeaderBuilder {
    public static buildBearerWith(token: Token): AuthorizationHeader {
        return { Authorization: `Bearer ${token}` };
    }
}
