




































import { Component, Vue, Watch } from 'vue-property-decorator';
import SideMenuHeader from './SideMenuHeader.vue';
import { Route } from 'vue-router';
import { Role } from '@/modules/user/domain/Types';

interface RouteIndexes { [key: string]: string; }

@Component({
    components: {SideMenuHeader},
})
export default class SideMenu extends Vue {
  private currentRouteName: string | null = null;
  private readonly routeIndexes: RouteIndexes = {
    'empty-route': '0',
    'campaign-listing': '1',
    'create-campaign': '1',
    'view-campaign': '1',
    'create-creative': '1',
    'view-creative': '1',
    'advertiser-listing': '2',
    'create-advertiser': '2',
    'view-advertiser': '2',
    'portal-listing': '3',
    'create-portal': '3',
    'view-portal': '3',
    'category-listing': '4',
    'stations-listing': '5',
    'user-listing': '6',
  };

  @Watch('$route')
  private onRouteChange(route: Route) {
    this.currentRouteName = route.name || '';
  }

  private hasRole(roleNeeded: string) {
    const roles: Role[] = this.$store.getters['sessionStore/roles'];
    return roles.find((role) => role === roleNeeded);
  }

  public close() {
    (this.$parent.$parent.$refs.hamburger as any).checked = false;
  }

  get activeRoute() {
    if (!this.currentRouteName) {
      return this.routeIndexes['empty-route'];
    }

    return this.routeIndexes[this.currentRouteName] || this.routeIndexes['empty-route'];
  }
}
