import { Token } from './Types';
import { User } from '@/modules/user/domain/User';

export class Session {
    public static for(user: User, token: Token) {
        return new Session(
            user,
            token,
            );
    }

    private constructor(
        private readonly user: User,
        private readonly token: Token,
    ) {}

    public getUser() {
        return this.user;
    }

    public getToken() {
        return this.token;
    }
}
