import { Id, Role, Group } from './Types';
import { UserInformationResponse } from '@/modules/session/rest/response/UserInformationResponse';

export class User {
    public static from(userInformationResponse: UserInformationResponse) {
        return new User(
            userInformationResponse.sub,
            userInformationResponse.roles,
            userInformationResponse.groups,
            userInformationResponse.name,
        );
    }

    private constructor(
        private readonly id: Id,
        private readonly roles: Role[],
        private readonly groups: Group[],
        private readonly fullName: string,
    ) {}

    public getRoles() {
        return this.roles;
    }

    public getId() {
        return this.id;
    }

    public getFullName() {
        return this.fullName;
    }
}
