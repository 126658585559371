import Vue from 'vue';
import Vuex from 'vuex';
import { SessionState, sessionStore } from './modules/session/SessionStore';

Vue.use(Vuex);

export interface RootState {
  sessionStore: SessionState;
}

export default new Vuex.Store({
  modules: {
    sessionStore,
  },
});
