import { ActionContext } from 'vuex';
import { RootState } from '@/store';
import { SessionService } from './service/SessionService';
import {CreateSessionRequest} from './rest/request/CreateSessionRequest';
import { Session } from './domain/Session';
import { Storage } from '@/infrastructure/storage/Storage';
import { StorageItem } from '@/infrastructure/storage/StorageItem';
import { configuration } from '@/config';
import { Token } from './domain/Types';
import { Time } from '@/utils/Time';
import router from '@/router';
import { User } from '../user/domain/User';

export interface SessionState {
    session: Session | null;
    validateSessionInterval: number | null;
}

export const sessionStore = {
    namespaced: true,
    state: {
        session: null,
        validateSessionInterval: null,
    },
    getters: {
        isAuthenticated: (state: SessionState) => Boolean(state.session),
        token: (state: SessionState) => {
            if (!state.session) {
                return '';
            }

            return state.session.getToken();
        },
        roles: (state: SessionState) => {
            if (!state.session) {
                return [];
            }

            return state.session.getUser().getRoles();
        },
        id: (state: SessionState) => {
            if (!state.session) {
                return [];
            }

            return state.session.getUser().getId();
        },
    },
    mutations: {
        SET_SESSION(state: SessionState, newSession: Session) {
            state.session = newSession;
        },
        SET_INTERVAL(state: SessionState, interval: number) {
            state.validateSessionInterval = interval;
        },
        CLEAR_SESSION(state: SessionState) {
            state.session = null;
            state.validateSessionInterval = null;
        },
    },
    actions: {
        async create({commit, dispatch}: ActionContext<SessionState, RootState>,
                     createSessionRequest: CreateSessionRequest) {
            const newSession = await SessionService.createSession(createSessionRequest);
            Storage.put<Token>(StorageItem.for(configuration.LOCAL_STORAGE_TOKEN_KEY, newSession.getToken()));
            dispatch('createInterval');
            commit('SET_SESSION', newSession);
        },
        async restoreWith({commit, dispatch}: ActionContext<SessionState, RootState>, token: Token) {
            const userInformation = await SessionService.fetchUserInformation(token);
            const user = User.from(userInformation);
            const newSession = Session.for(user, token);
            dispatch('createInterval');
            commit('SET_SESSION', newSession);
        },
        createInterval({commit, dispatch}: ActionContext<SessionState, RootState>) {
            const interval = setInterval(() => dispatch('logoutIfSessionExpired'), Time.ofMinutes(1).asMilliseconds());
            commit('SET_INTERVAL', interval);
        },
        async logoutIfSessionExpired({state, dispatch}: ActionContext<SessionState, RootState>) {
            let sessionActive = false;
            if (state.session) {
                sessionActive = await SessionService.isSessionActive(state.session.getToken());
            }

            if (!sessionActive) {
                dispatch('closeSession');
            }
        },
        closeSession({commit, state}: ActionContext<SessionState, RootState>) {
            if (state.validateSessionInterval) {
                clearInterval(state.validateSessionInterval);
            }

            commit('CLEAR_SESSION');
            Storage.clear();
            router.push('/login');
        },
    },
};
