// @ts-ignore
import locale from 'element-ui/lib/locale/lang/fr';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import './theme/theme.scss';
import './theme/element-theme.scss';
import { restoreSessionIfExists } from './modules/session/utils/RestoreSession';

Vue.config.productionTip = false;
Vue.use(ElementUI, {locale});

async function initializeApplication() {
  await restoreSessionIfExists();
  createVueInstance();
}

function createVueInstance() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

initializeApplication();
