import { StorageKey } from './types';

export class StorageItem {
    public static for(key: StorageKey, value: any) {
        return new StorageItem(key, value);
    }

    private constructor(private readonly key: StorageKey, private readonly value: any) {}

    public getKey() {
        return this.key;
    }

    public getValue() {
        return this.value;
    }
}
