
















import { Component, Vue } from 'vue-property-decorator';
import HeadBar from './components/header/HeadBar.vue';
import SideMenu from './components/sidemenu/SideMenu.vue';

@Component({
  components: {HeadBar, SideMenu},
})
export default class App extends Vue {
  get displayMenu() {
    return this.$store.getters['sessionStore/isAuthenticated'];
  }
}
