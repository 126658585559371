export interface Configuration {
    KEYCLOAK_OPENID_URL: string;
    KEYCLOAK_ADMIN_URL: string;
    KEYCLOAK_CLIENT_ID: string;
    KEYCLOAK_CLIENT: string;
    KEYCLOAK_CLIENT_SECRET: string;
    KEYCLOAK_GROUP: string;
    KEYCLOAK_REALMS: string;
    LOCAL_STORAGE_TOKEN_KEY: string;
    GATEWAY_URL: string;
    MEDIA_URL: string;
}

export const configuration: Configuration = {
    KEYCLOAK_OPENID_URL: process.env.VUE_APP_KEYCLOAK_OPENID_URL || '',
    KEYCLOAK_ADMIN_URL: process.env.VUE_APP_KEYCLOAK_ADMIN_URL || '',
    KEYCLOAK_CLIENT_ID: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || '',
    KEYCLOAK_CLIENT: process.env.VUE_APP_KEYCLOAK_CLIENT || '',
    KEYCLOAK_CLIENT_SECRET: process.env.VUE_APP_KEYCLOAK_CLIENT_SECRET || '',
    KEYCLOAK_GROUP: process.env.VUE_APP_KEYCLOAK_GROUP || '',
    KEYCLOAK_REALMS: process.env.VUE_APP_KEYCLOAK_REALMS || '',
    LOCAL_STORAGE_TOKEN_KEY: process.env.VUE_APP_LOCAL_STORAGE_TOKEN_KEY || '',
    GATEWAY_URL: process.env.VUE_APP_GATEWAY_URL || '',
    MEDIA_URL: process.env.VUE_APP_MEDIA_URL || '',
};

