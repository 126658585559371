
























import {Component, Vue} from 'vue-property-decorator';


@Component
export default class HeadBarMenu extends Vue {
    private async logOut() {
        await this.$store.dispatch('sessionStore/closeSession');
    }

    private editAccount() {
        this.$message.warning('Cette fonction n\'est pas encore implémentée!');
    }

    get fullName() {
        return this.$store.state.sessionStore.session.getUser().getFullName();
    }
}
