













import {Component, Vue} from 'vue-property-decorator';

@Component
export default class SideMenuHeader extends Vue {
    get fullName() {
        return this.$store.state.sessionStore.session.getUser().getFullName();
    }
}
