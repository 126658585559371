import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import { Role } from './modules/user/domain/Types';

Vue.use(Router);

export enum Roles {
  ROLE_SUPERADMIN = 'ROLE_SUPERADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum RouteAccess {
  AUTHENTICATED,
  GUEST,
}

export interface RouteMeta {
  access?: RouteAccess;
  roleNeeded?: Roles;
}

const router = new Router({
  routes: [
    {
      path: '/view-creative/:creativeId',
      name: 'view-creative',
      component: () => import('@/modules/creative/views/ViewCreative.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/create-creative/:campaignId',
      name: 'create-creative',
      component: () => import('@/modules/creative/views/CreateCreative.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/view-campaign/:campaignId',
      name: 'view-campaign',
      component: () => import('@/modules/campaign/views/ViewCampaign.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/category-listing',
      name: 'category-listing',
      component: () => import('@/modules/category/views/CategoryListing.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/view-category/:categoryId',
      name: 'view-category',
      component: () => import('@/modules/category/views/ViewCategory.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/create-category',
      name: 'create-category',
      component: () => import('@/modules/category/views/ViewCategory.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/user-listing',
      name: 'user-listing',
      component: () => import('@/modules/user/views/UserListing.vue'),
      meta: { roleNeeded: Roles.ROLE_SUPERADMIN },
    },
    {
      path: '/view-user/:userId',
      name: 'view-user',
      component: () => import('@/modules/user/views/ViewUser.vue'),
      meta: { roleNeeded: Roles.ROLE_SUPERADMIN },
    },
    {
      path: '/create-user',
      name: 'create-user',
      component: () => import('@/modules/user/views/ViewUser.vue'),
      meta: { roleNeeded: Roles.ROLE_SUPERADMIN },
    },
    {
      path: '/view-portal/:portalId',
      name: 'view-portal',
      component: () => import('@/modules/portal/views/PortalFormView.vue'),
      meta: { roleNeeded: Roles.ROLE_SUPERADMIN },
    },
    {
      path: '/create-portal',
      name: 'create-portal',
      component: () => import('@/modules/portal/views/CreatePortal.vue'),
      meta: { roleNeeded: Roles.ROLE_SUPERADMIN },
    },
    {
      path: '/portal-listing',
      name: 'portal-listing',
      component: () => import('@/modules/portal/views/PortalListing.vue'),
      meta: { roleNeeded: Roles.ROLE_SUPERADMIN },
    },
    {
      path: '/create-campaign',
      name: 'create-campaign',
      component: () => import('@/modules/campaign/views/CreateCampaign.vue'),
      meta: { roleNeeded: Roles.ROLE_ADMIN },
    },
    {
      path: '/view-advertiser/:advertiserId',
      name: 'view-advertiser',
      component: () => import('@/modules/advertiser/views/ViewAdvertiser.vue'),
      meta: { roleNeeded:  Roles.ROLE_ADMIN },
    },
    {
      path: '/create-advertiser',
      name: 'create-advertiser',
      component: () => import('@/modules/advertiser/views/CreateAdvertiser.vue'),
      meta: { roleNeeded:  Roles.ROLE_ADMIN },
    },
    {
      path: '/advertiser-listing',
      name: 'advertiser-listing',
      component: () => import('@/modules/advertiser/views/AdvertiserListing.vue'),
      meta: { roleNeeded:  Roles.ROLE_ADMIN },
    },
    {
      path: '/campaign-listing',
      name: 'campaign-listing',
      component: () => import('@/modules/campaign/views/CampaignListing.vue'),
      meta: { roleNeeded:  Roles.ROLE_ADMIN },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/modules/login/views/Login.vue'),
      meta: { access: RouteAccess.GUEST },
    },
    {
      path: '*',
      redirect: '/login',
      component: () => import('@/modules/login/views/Login.vue'),
      meta: { access: RouteAccess.GUEST },
    },
    {
      path: '/stations-listing',
      name: 'stations-listing',
      component: () => import('@/modules/stations/views/StationsListing.vue'),
      meta: { roleNeeded:  Roles.ROLE_ADMIN },
    },
    {
      path: '/view-station/:code',
      name: 'view-station',
      component: () => import('@/modules/stations/views/StationsView.vue'),
      meta: { roleNeeded:  Roles.ROLE_ADMIN },
    },
  ],
});

router.beforeEach(async (to: any, from: any, next: any) => {
  const routeMeta = to.meta as RouteMeta;
  const isAuthenticated = store.getters['sessionStore/isAuthenticated'];
  const roles: Role[] = store.getters['sessionStore/roles'];

  if (routeMeta.roleNeeded) {
    if (!isAuthenticated) {
      return next('/login');
    } else if (roles.find((role) => role === routeMeta.roleNeeded)) {
      return next();
    } else {
      return next('/campaign-listing');
    }
  }

  if (isAuthenticated) {
    return next('/campaign-listing');
  }
  return next();
});

export default router;
